import React, {lazy, Suspense} from 'react';
import {withTranslation, WithTranslation} from "react-i18next";
import ApplicationState from "src/model/ApplicationState";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";
import initialMetricsPublisher, {makeMetricsDriver} from 'src/metrics';
import MetricsUtil from 'src/utils/MetricsUtil';
import {HeaderComponent} from "src/components/header/HeaderComponent";
import {routes} from "src/config/routes";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import LoadingStatusComponent from "src/components/common/LoadingStatusComponent";
import SubpageName from "src/model/SubpageName";

import {Amplify, Auth} from "aws-amplify";
import {currentCognitoConfig} from "src/auth/config";
import {CognitoUser} from "amazon-cognito-identity-js";

const DefaultPageLayout = lazy(() => import("src/components/page/DefaultPageLayout"));
const BannerConfigLayout = lazy(() => import("src/components/page/banner/BannerConfigLayout"));
const FutureFeeLayout = lazy(() => import("src/components/page/futureFee/FutureFeeLayout"));


Amplify.configure({
    Auth: {
        identityPoolId: currentCognitoConfig.identityPoolId,
        userPoolId: currentCognitoConfig.userPoolId, //'us-west-2_xxxxxxxxx'
        userPoolWebClientId: currentCognitoConfig.clientId, //'xxxxxxxxxxxxxxxxxxxxxxxxxx',
        region: currentCognitoConfig.region,
        mandatorySignIn: true,
        oauth: {
            domain: currentCognitoConfig.domain,
            redirectSignIn: window.location.origin, // this will have to be replaced with the website url when you deploy
            redirectSignOut: window.location.origin, // this will have to be replaced with the website url when you deploy
            scope: ["openid"],
            responseType: "code"
        },
    },
    API: {
        endpoints: [
            {
                name: "FeeTechPortalLambda",
                endpoint: currentCognitoConfig.endpoint,
                custom_header: async () => ({
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                }),
            },
        ],
    },
})

interface DispatchProps {
}

interface OwnProps {
}

interface StateProps {
}

interface State {
    currentSubPage: SubpageName,
    userAlias: string
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => (
    bindActionCreators({}, dispatch)
);

const mapStateToProps = (state: ApplicationState): StateProps => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = StateProps & DispatchProps & OwnProps;


export class AppMainLayout extends React.Component<Props, State> {
    readonly metricsUtil = new MetricsUtil(makeMetricsDriver(), initialMetricsPublisher, 'FeeTechPortal');

    constructor(props: Props) {
        super(props);

        this.state = {
            currentSubPage: SubpageName.Home,
            userAlias: ''
        }

        this.loginCallback = this.loginCallback.bind(this);
    }

    componentDidMount() {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                if (!user) {
                    this.loginRedirect();
                } else {
                    this.loginCallback(user);
                }
            })
            .catch(this.loginRedirect);
    }

    private loginRedirect() {
        Auth.federatedSignIn();
    }

    private loginCallback(user: CognitoUser) {
        this.setState({
            userAlias: user.getUsername().split('_')[1]
        })
    }

    render() {
        return (
            <>
                <BrowserRouter>
                    <HeaderComponent routes={routes} currentSubPage={this.state.currentSubPage}></HeaderComponent>
                    <Routes>
                        <Route path='/' element={
                            <Suspense fallback={<LoadingStatusComponent/>}>
                                <DefaultPageLayout metricsUtil={this.metricsUtil} userAlias={this.state.userAlias}/>
                            </Suspense>
                        }>
                        </Route>
                        <Route path="/banner-conf/*" element={
                            <Suspense fallback={<LoadingStatusComponent/>}>
                                <BannerConfigLayout metricsUtil={this.metricsUtil} userAlias={this.state.userAlias}/>
                            </Suspense>
                        }/>
                        <Route path='/futurefee/' element={
                            <Suspense fallback={<LoadingStatusComponent/>}>
                                <FutureFeeLayout userAlias={this.state.userAlias}/>
                            </Suspense>
                        }/>
                    </Routes>
                </BrowserRouter>
            </>
        );
    }
}

export default withTranslation()(connector(AppMainLayout));
