import RequestStatus from "../model/RequestStatus";
import {ProfileListQueryResponse} from "src/model/Profile";

export const PROFILE_LIST_QUERY_START = 'PROFILE_QUERY_START';
export const PROFILE_LIST_QUERY_SUCCESS = 'PROFILE_QUERY_SUCCESS';
export const PROFILE_LIST_QUERY_FAILED = 'PROFILE_QUERY_FAILED';

export interface ProfileListQueryStartAction {
    readonly type: string;
    readonly requestStatus: RequestStatus;
    readonly projectId: string;
    readonly revisionId: string;
}

export const setProfileListQueryStart = (projectId: string, revisionId: string): ProfileListQueryStartAction => ({
    type: PROFILE_LIST_QUERY_START,
    requestStatus: RequestStatus.LOADING,
    projectId: projectId,
    revisionId: revisionId
});

export interface GetProfileListQueryComplete {
    readonly type: string;
    readonly requestStatus: RequestStatus;
    readonly profileList: any;
}

export const getProfileListQuerySuccessAction = (response: ProfileListQueryResponse): GetProfileListQueryComplete => ({
    type: PROFILE_LIST_QUERY_SUCCESS,
    requestStatus: RequestStatus.SUCCESS,
    profileList: response
});

export const getProfileListQueryFailedAction = (): GetProfileListQueryComplete => ({
    type: PROFILE_LIST_QUERY_FAILED,
    requestStatus: RequestStatus.ERROR,
    profileList: null
});