import RequestStatus from "../model/RequestStatus";
import {RevisionListQueryResponse} from "src/model/Revision";

export const REVISION_LIST_QUERY_START = 'REVISION_QUERY_START';
export const REVISION_LIST_QUERY_SUCCESS = 'REVISION_QUERY_SUCCESS';
export const REVISION_LIST_QUERY_FAILED = 'REVISION_QUERY_FAILED';

export interface RevisionListQueryStartAction {
    readonly type: string;
    readonly requestStatus: RequestStatus;
    readonly projectId: string;
}

export const setRevisionListQueryStart = (projectId: string): RevisionListQueryStartAction => ({
    type: REVISION_LIST_QUERY_START,
    requestStatus: RequestStatus.LOADING,
    projectId: projectId

});

export interface GetRevisionListQueryComplete {
    readonly type: string;
    readonly requestStatus: RequestStatus;
    readonly revisionList: any;
}

export const getRevisionListQuerySuccessAction = (response: RevisionListQueryResponse): GetRevisionListQueryComplete => ({
    type: REVISION_LIST_QUERY_SUCCESS,
    requestStatus: RequestStatus.SUCCESS,
    revisionList: response
});

export const getRevisionListQueryFailedAction = (): GetRevisionListQueryComplete => ({
    type: REVISION_LIST_QUERY_FAILED,
    requestStatus: RequestStatus.ERROR,
    revisionList: null
});