import {call, put, takeLatest} from "redux-saga/effects";
import ApiUtil, {HTTP_METHOD} from "src/utils/ApiUtil";
import {
    FUTURE_FEE_CONFIG_SAVE_START,
    FutureFeeConfigSaveStartAction,
    saveFutureFeeConfigFailed, saveFutureFeeConfigSuccess, setFutureFeeConfigQueryStart,
} from "src/actions/FutureFeeConfigQueryAction";

export function* saveFutureFeeConfig(action: FutureFeeConfigSaveStartAction) {
    try {
        let payload = {
            login: action.userLogin,
            countryCode: action.marketplaces,
            feeType: action.feeType,
            date: action.futureFeeDate,
            beginToDisplayDate: action.beginToDisplayDate
        }
        // @ts-ignore
        yield call(ApiUtil.callApiWithTimeout, `futurefeeconfig`, payload, 30 * 1000, HTTP_METHOD.POST);

        yield put(saveFutureFeeConfigSuccess());
        yield put(setFutureFeeConfigQueryStart());
    } catch (error) {
        yield put(saveFutureFeeConfigFailed());
        return;
    }
}

export default function* futureFeeConfigSave() {
    yield takeLatest(FUTURE_FEE_CONFIG_SAVE_START, saveFutureFeeConfig);
}