import ProjectListQueryReducer from "src/reducers/ProjectListQueryReducer";
import RevisionListQueryReducer from "src/reducers/RevisionListQueryReducer";
import ProfileListQueryReducer from "src/reducers/ProfileListQueryReducer"
import RevisionSaveReducer from "src/reducers/RevisionSaveReducer";
import FutureFeeConfigQueryReducer from "./FutureFeeConfigQueryReducer";
import FutureFeeConfigSaveReducer from "./FutureFeeConfigSaveReducer";
import FutureFeeConfigDeleteReducer from "src/reducers/FutureFeeConfigDeleteReducer";

function createReducer() {
    let reducers = {
        projectListRes: ProjectListQueryReducer,
        revisionListRes: RevisionListQueryReducer,
        profileListRes: ProfileListQueryReducer,
        revisionSaveRes: RevisionSaveReducer,
        futureFeeConfigRes: FutureFeeConfigQueryReducer,
        futureFeeConfigSaveRes: FutureFeeConfigSaveReducer,
        futureFeeConfigDeleteRes: FutureFeeConfigDeleteReducer,
    };

    return reducers;
}

export default createReducer();