import SubpageName from "src/model/SubpageName";

export interface Route {
    title: string,
    href: string,
    name?: SubpageName,
    routes?: Route[],
    separator?: boolean
}

export const routes: Route[] = [
    {
        title: 'Home',
        href: '/',
        name: SubpageName.Home,
    },
    {
        title: 'Banner Config',
        href: '/banner-conf',
        name : SubpageName.PageConfig,
    },
    {
        title: 'Future Fee Config',
        href:'/futurefee',
        name: SubpageName.PageConfig,
    }
]