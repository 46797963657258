import RequestStatus from "src/model/RequestStatus";
import GetFutureFeeConfigResponse from "src/model/FutureFee";
import {
    FUTURE_FEE_CONFIG_QUERY_FAILED, FUTURE_FEE_CONFIG_QUERY_START,
    FUTURE_FEE_CONFIG_QUERY_SUCCESS,
    GetFutureFeeConfigQueryComplete
} from "src/actions/FutureFeeConfigQueryAction";

const initialState: GetFutureFeeConfigResponse = {
    requestStatus: RequestStatus.NOT_LOADED,
    futureFeeConfig: null,
};

export default (
    state: GetFutureFeeConfigResponse = initialState,
    action: GetFutureFeeConfigQueryComplete
): GetFutureFeeConfigResponse => {
    switch (action.type) {
        case FUTURE_FEE_CONFIG_QUERY_START:
            return {
                requestStatus: RequestStatus.LOADING,
                futureFeeConfig: null,
            };
        case FUTURE_FEE_CONFIG_QUERY_SUCCESS:
            return {
                requestStatus: RequestStatus.SUCCESS,
                futureFeeConfig: action.futureFeeConfig,
            };
        case FUTURE_FEE_CONFIG_QUERY_FAILED:
            return {
                requestStatus: RequestStatus.ERROR,
                futureFeeConfig: null,
            };
        default:
            return state;
    }
};