import RequestStatus from "src/model/RequestStatus";
import {SaveFutureFeeConfigResponse} from "src/model/FutureFee";
import {
    FUTURE_FEE_CONFIG_SAVE_FAILED,
    FUTURE_FEE_CONFIG_SAVE_START,
    FUTURE_FEE_CONFIG_SAVE_SUCCESS,
    FutureFeeConfigSaveComplete,
} from "src/actions/FutureFeeConfigQueryAction";

const initialState: SaveFutureFeeConfigResponse = {
    requestStatus: RequestStatus.NOT_LOADED,
};

export default (
    state: SaveFutureFeeConfigResponse = initialState,
    action: FutureFeeConfigSaveComplete
): SaveFutureFeeConfigResponse => {
    switch (action.type) {
        case FUTURE_FEE_CONFIG_SAVE_START:
            return {
                requestStatus: RequestStatus.LOADING,
            };
        case FUTURE_FEE_CONFIG_SAVE_SUCCESS:
            return {
                requestStatus: RequestStatus.SUCCESS,
            };
        case FUTURE_FEE_CONFIG_SAVE_FAILED:
            return {
                requestStatus: RequestStatus.ERROR,
            };
        default:
            return state;
    }
};