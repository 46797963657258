import RequestStatus from "../model/RequestStatus";
import {
    GetProjectListQueryComplete,
    PROJECT_LIST_QUERY_FAILED,
    PROJECT_LIST_QUERY_START,
    PROJECT_LIST_QUERY_SUCCESS
} from "src/actions/ProjectListQueryAction";
import {GetProjectListQueryResponse} from "src/model/Project";

const initialState: GetProjectListQueryResponse = {
    requestStatus: RequestStatus.NOT_LOADED,
    data: null,
};

export default (
    state: GetProjectListQueryResponse = initialState,
    action: GetProjectListQueryComplete
): GetProjectListQueryResponse => {
    switch (action.type) {
        case PROJECT_LIST_QUERY_START:
            return {
                requestStatus: RequestStatus.LOADING,
                data: null,
            };
        case PROJECT_LIST_QUERY_SUCCESS:
            return {
                requestStatus: RequestStatus.SUCCESS,
                data: action.projectList,
            };
        case PROJECT_LIST_QUERY_FAILED:
            return {
                requestStatus: RequestStatus.ERROR,
                data: null,
            };
        default:
            return state;
    }
};
