import {call, put, takeLatest} from 'redux-saga/effects';
import {
    getProjectListQueryFailedAction,
    getProjectListQuerySuccessAction,
    PROJECT_LIST_QUERY_START,
    ProjectListQueryStartAction
} from "src/actions/ProjectListQueryAction";
import ApiUtil from "src/utils/ApiUtil";

export function* getProjectList(action: ProjectListQueryStartAction) {
    try {
        // @ts-ignore
        const response = yield call(ApiUtil.callApiWithTimeout, "banner/projects");
        yield put(getProjectListQuerySuccessAction(response));
    } catch (error) {
        console.error("Call API /projects error" + error)
        yield put(getProjectListQueryFailedAction());
        return;
    }
}

export default function* projectListQuery() {
    yield takeLatest(PROJECT_LIST_QUERY_START, getProjectList);
}