import RequestStatus from "src/model/RequestStatus";

export interface FutureFeeConfig{
    readonly futureFeeConfigMap: Map<string, Map<string, Array<number>>>;// marketplace -> feeType -> date
}

export interface GetFutureFeeConfigResponse {
    readonly requestStatus: RequestStatus;
    readonly futureFeeConfig: FutureFeeConfig | null;
}

export interface SaveFutureFeeConfigResponse{
    readonly requestStatus: RequestStatus;
}

export interface DeleteFutureFeeConfigResponse{
    readonly requestStatus: RequestStatus;
}

export enum FeeType{
    FULFILLMENT_FEE="FulfillmentFee",
    REFERRAL_FEE="ReferralFee",
    STORAGE_FEE= "StorageFee",
}

export default GetFutureFeeConfigResponse