import RequestStatus from "src/model/RequestStatus";
import {FutureFeeConfig} from "src/model/FutureFee";


export const FUTURE_FEE_CONFIG_QUERY_START = 'FUTURE_FEE_CONFIG_QUERY_START';
export const FUTURE_FEE_CONFIG_QUERY_SUCCESS = 'FUTURE_FEE_CONFIG_QUERY_SUCCESS';
export const FUTURE_FEE_CONFIG_QUERY_FAILED = 'FUTURE_FEE_CONFIG_QUERY_FAILED';
export const FUTURE_FEE_CONFIG_DELETE_START = 'FUTURE_FEE_CONFIG_DELETE_START';
export const FUTURE_FEE_CONFIG_SAVE_START = 'FUTURE_FEE_CONFIG_SAVE_START';
export const FUTURE_FEE_CONFIG_SAVE_SUCCESS = 'FUTURE_FEE_CONFIG_SAVE_SUCCESS';
export const FUTURE_FEE_CONFIG_SAVE_FAILED = 'FUTURE_FEE_CONFIG_SAVE_FAILED';
export const FUTURE_FEE_CONFIG_DELETE_SUCCESS = 'FUTURE_FEE_CONFIG_DELETE_SUCCESS';
export const FUTURE_FEE_CONFIG_DELETE_FAILED = 'FUTURE_FEE_CONFIG_DELETE_FAILED';

export interface FutureFeeConfigQueryStartAction {
    readonly type: string;
    readonly requestStatus: RequestStatus;
}

export const setFutureFeeConfigQueryStart = (): FutureFeeConfigQueryStartAction => ({
    type: FUTURE_FEE_CONFIG_QUERY_START,
    requestStatus: RequestStatus.LOADING,
})

export interface GetFutureFeeConfigQueryComplete {
    readonly type: string;
    readonly requestStatus: RequestStatus;
    readonly futureFeeConfig: FutureFeeConfig | null;
}

export const getFutureFeeConfigSuccessAction = (futureFeeConfig: FutureFeeConfig): GetFutureFeeConfigQueryComplete => ({
    type: FUTURE_FEE_CONFIG_QUERY_SUCCESS,
    requestStatus: RequestStatus.SUCCESS,
    futureFeeConfig: futureFeeConfig
})

export const getFutureFeeConfigFailedAction = (): GetFutureFeeConfigQueryComplete => ({
    type: FUTURE_FEE_CONFIG_QUERY_FAILED,
    requestStatus: RequestStatus.ERROR,
    futureFeeConfig: null
})

export interface FutureFeeConfigSaveStartAction {
    readonly type: string;
    readonly requestStatus: RequestStatus;
    readonly userLogin: string;
    readonly marketplaces: Array<string>;
    readonly feeType: string;
    readonly futureFeeDate: number;
    readonly beginToDisplayDate: number;
}

export interface FutureFeeConfigSaveComplete {
    readonly type: string;
    requestStatus: RequestStatus;
}

export const setFutureFeeConfigSaveStart = (userLogin: string, marketplaces: Set<string>, feeType: string, futureFeeDate: number, beginToDisplayDate: number): FutureFeeConfigSaveStartAction => ({
    type: FUTURE_FEE_CONFIG_SAVE_START,
    requestStatus: RequestStatus.LOADING,
    userLogin: userLogin,
    marketplaces: Array.from(marketplaces),
    feeType: feeType,
    futureFeeDate: futureFeeDate,
    beginToDisplayDate: beginToDisplayDate,
})

export const saveFutureFeeConfigSuccess = (): FutureFeeConfigSaveComplete => ({
    type: FUTURE_FEE_CONFIG_SAVE_SUCCESS,
    requestStatus: RequestStatus.SUCCESS,
})

export const saveFutureFeeConfigFailed = ():FutureFeeConfigSaveComplete =>({
    type: FUTURE_FEE_CONFIG_SAVE_FAILED,
    requestStatus: RequestStatus.ERROR
})

export interface FutureFeeConfigDeleteStartAction {
    readonly type: string;
    readonly requestStatus: RequestStatus;
    readonly userLogin: string;
    readonly countryCode: string;
    readonly feeType?: string;
    readonly date?: number;
}
export interface FutureFeeConfigDeleteComplete {
    readonly type: string;
    requestStatus: RequestStatus;
}

export const setFutureFeeConfigDeleteStart = (userLogin: string, countryCode: string, feeType?: string, date?: number): FutureFeeConfigDeleteStartAction => ({
    type: FUTURE_FEE_CONFIG_DELETE_START,
    requestStatus: RequestStatus.LOADING,
    userLogin: userLogin,
    countryCode: countryCode,
    feeType: feeType,
    date: date,
})

export const deleteFutureFeeConfigSuccess = (): FutureFeeConfigDeleteComplete => ({
    type: FUTURE_FEE_CONFIG_DELETE_SUCCESS,
    requestStatus: RequestStatus.SUCCESS,
})

export const deleteFutureFeeConfigFailed = ():FutureFeeConfigDeleteComplete =>({
    type: FUTURE_FEE_CONFIG_DELETE_FAILED,
    requestStatus: RequestStatus.ERROR
})
