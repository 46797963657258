import React from 'react';
import {Route} from "src/config/routes";
import {KatIcon, KatLabel} from "@amzn/katal-react";

import SubpageName from "src/model/SubpageName";
import {NavLink} from "react-router-dom";

interface HeaderProps {
    routes: Route[];
    currentSubPage: SubpageName;
}

interface State {
}

export class HeaderComponent extends React.Component<HeaderProps, State> {

    constructor(props: HeaderProps) {
        super(props);
    }


    render() {
        return (
            <div id="app-header">
                <div className="app-header-logo">
                    <KatIcon name="monetization_on" size="medium"/>
                    <KatLabel className="header-title" text="FeeSXTech Portal"/>
                </div>
                <nav className="app-header-toggle">
                    {
                        this.props.routes.map(({title, href, name}) => (
                        <NavLink key={'header-' + name} className={"nav-link"} to={href}>
                            {title}
                        </NavLink>
                    ))}
                </nav>
            </div>
        );
    }
}
