import {Auth} from 'aws-amplify';
import {pipelineStage, PipelineStageEnum} from "src/config/Config";
import {CognitoConfig} from "src/constructs/cognito-config";

export const CognitoConfigs: Map<string, CognitoConfig> = new Map<string, CognitoConfig>([
        [
            PipelineStageEnum.BetaNA,
            {
                userPoolId: 'us-east-1_E3GHM1XFT',
                clientId: '2c18576kalkefbcuqqno4c4enr',
                domain: 'fee-tech-portal-beta.auth.us-east-1.amazoncognito.com',
                region: 'us-east-1',
                endpoint: 'https://7okcoorm2b.execute-api.us-east-1.amazonaws.com/Beta',
                identityPoolId: 'us-east-1:7797cd5b-eb2c-4572-ad82-c0b04f8f7def'
            }
        ], [
            PipelineStageEnum.BetaFE,
            {
                userPoolId: 'us-west-2_3aM4rrCHU',
                clientId: '3fn7u4h5l635s7mb21km7s14ja',
                domain: 'fee-tech-portal-beta.auth.us-west-2.amazoncognito.com',
                region: 'us-west-2',
                endpoint: 'https://8f0jyiexmc.execute-api.us-west-2.amazonaws.com/Beta',
                identityPoolId: 'us-west-2:ba10af22-1b8a-4447-b54a-d47d7b74e3a5'
            }
        ], [
            PipelineStageEnum.BetaEU,
            {
                userPoolId: 'eu-west-1_mGl3UA0Od',
                clientId: '65knu8e7g59a551s793pvotfbu',
                domain: 'fee-tech-portal-beta.auth.eu-west-1.amazoncognito.com',
                region: 'eu-west-1',
                endpoint: 'https://yhd1aykbt3.execute-api.eu-west-1.amazonaws.com/Beta',
                identityPoolId: 'eu-west-1:0d5ece5e-3177-457c-9795-8cb01dc895d2'
            }
        ], [
            PipelineStageEnum.GammaNA,
            {
                userPoolId: 'us-east-1_3WvMHogiN',
                clientId: '7bcd8dom852k6evmdsfpd2rmqd',
                domain: 'fee-tech-portal-gamma.auth.us-east-1.amazoncognito.com',
                region: 'us-east-1',
                endpoint: 'https://6mangovyrh.execute-api.us-east-1.amazonaws.com/Gamma',
                identityPoolId: 'us-east-1:cce851bf-308c-43ec-84a6-4bb32a0f5d21'
            }
        ], [
            PipelineStageEnum.GammaFE,
            {
                userPoolId: 'us-west-2_0gD6Hb9Ln',
                clientId: '5861mbqcr2l6muvl37cf2sdhq',
                domain: 'fee-tech-portal-gamma.auth.us-west-2.amazoncognito.com',
                region: 'us-west-2',
                endpoint: 'https://pebivomsf8.execute-api.us-west-2.amazonaws.com/Gamma',
                identityPoolId: 'us-west-2:70df85fa-a229-4d54-9c18-05744b9a405d'
            }
        ], [
            PipelineStageEnum.GammaEU,
            {
                userPoolId: 'eu-west-1_7GEbRI2iY',
                clientId: '52oe1hrf4htansfdj1875kmtri',
                domain: 'fee-tech-portal-gamma.auth.eu-west-1.amazoncognito.com',
                region: 'eu-west-1',
                endpoint: 'https://xee70n2io3.execute-api.eu-west-1.amazonaws.com/Gamma',
                identityPoolId: 'eu-west-1:89ff3595-9272-4c7d-aeb0-19916cc4e520'
            }
        ], [
            PipelineStageEnum.ProdNA,
            {
                userPoolId: 'us-east-1_7GarVf9yO',
                clientId: '5hb5nn7nua7vna9h72mt18dhgu',
                domain: 'fee-tech-portal-prod.auth.us-east-1.amazoncognito.com',
                region: 'us-east-1',
                endpoint: 'https://qt1ga0nxlk.execute-api.us-east-1.amazonaws.com/Prod',
                identityPoolId: 'us-east-1:01158e37-8ed3-473e-8252-d4af91157fbd'
            }
        ], [
            PipelineStageEnum.ProdFE,
            {
                userPoolId: 'us-west-2_aw9EsU2Y1',
                clientId: '1gjae7is9drq47j4me28md1grh',
                domain: 'fee-tech-portal-prod.auth.us-west-2.amazoncognito.com',
                region: 'us-west-2',
                endpoint: 'https://hoeit9yqre.execute-api.us-west-2.amazonaws.com/Prod',
                identityPoolId: 'us-west-2:2fe4cbe3-b9de-44bb-a50b-e47eefedba03'
            }
        ], [
            PipelineStageEnum.ProdEU,
            {
                userPoolId: 'eu-west-1_shiua0Apf',
                clientId: 'nb2j5nje19bg2bup44mntq2aj',
                domain: 'fee-tech-portal-prod.auth.eu-west-1.amazoncognito.com',
                region: 'eu-west-1',
                endpoint: 'https://lcbayfpxr7.execute-api.eu-west-1.amazonaws.com/Prod',
                identityPoolId: 'eu-west-1:54f55fb1-06e9-48c8-97f2-eeaa845c71d7'
            }
        ]
    ]
);


// You can get the current config object
export const currentCognitoConfig = CognitoConfigs.get(pipelineStage)!;