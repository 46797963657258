import {all} from "redux-saga/effects";
import ProjectListQuerySaga from "./ProjectListQuerySaga";
import RevisionListQuerySaga from "./RevisionListQuerySaga"
import ProfileListQuerySaga from "./ProfileListQuerySaga"
import RevisionSaveSaga from "./RevisionSaveSaga"
import FutureFeeConfigDeleteSaga from './FutureFeeConfigDeleteSaga'
import FutureFeeConfigQuerySaga from './FutureFeeConfigQuerySaga'
import FutureFeeConfigSaveSaga from './FutureFeeConfigSaveSaga'

function createSagaArray() {
    return [
        ProjectListQuerySaga(),
        RevisionListQuerySaga(),
        ProfileListQuerySaga(),
        RevisionSaveSaga(),
        FutureFeeConfigDeleteSaga(),
        FutureFeeConfigQuerySaga(),
        FutureFeeConfigSaveSaga(),
    ];
}

export default function* combinedSaga() {
    // @ts-ignore
    let sagaArray = yield createSagaArray();

    yield all(
        sagaArray
    );
}