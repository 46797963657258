import i18next from "i18next";
import querystring from "querystring";
import MetricsUtil from "./MetricsUtil";
import {API} from "aws-amplify";

export enum HTTP_METHOD {
    GET = 'GET',
    POST = 'POST'
}

export class ApiError extends Error {
    public readonly apiErrorCode: number;

    constructor(apiErrorCode: number, errorMsg: string) {
        super(errorMsg);

        this.apiErrorCode = apiErrorCode;

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, ApiError.prototype);
    }
}

export default class ApiUtil {
    // @ts-ignore
    private static readonly default_api_base_path = "/api";
    private static readonly default_api_call_timeout = 30 * 1000;

    private static csrfToken: string = '';

    private static apiMetrics: MetricsUtil = MetricsUtil.getDefaultMetricUtil();

    private static getUrl(resource: string, method: HTTP_METHOD, payloads?: { [key: string]: any; }): string {
        if (method === HTTP_METHOD.GET) {
            return ApiUtil.default_api_base_path + '/' + resource + '?' + ApiUtil.getQueryString(payloads);
        } else {
            return ApiUtil.default_api_base_path + '/' + resource + '?' +
                ApiUtil.getQueryString(this.getPayloadsElementDeep(payloads, 'countryCode'));
        }
    }

    private static getPayloadsElementDeep(payloads?: { [key: string]: any; }, destKey?: string): object {
        if (!payloads) {
            return {}
        }
        for (let key in payloads) {
            if (key == destKey) {
                return {[key]: payloads[key]}
            }
            if (typeof payloads[key] == 'object') {
                let res = this.getPayloadsElementDeep(payloads[key], destKey)
                if (destKey && destKey in res) {
                    return res
                }
            }
        }
        return {}
    }

    private static getQueryString(payloads?: { [key: string]: any; }): string {
        if (!payloads) {
            payloads = {};
        }

        payloads['locale'] = i18next.language;

        return querystring.stringify(payloads);
    }

    private static callApi(
        resource: string,
        payloads?: { [key: string]: any; },
        method = HTTP_METHOD.GET,
        headers = {}
    ) {
        let timedAttemptMetrics = MetricsUtil.getTimedAttemptMetrics('apiCall');

        let url = ApiUtil.getUrl(resource, method, payloads);

        return ApiUtil.chooseMethod(method, url, payloads, headers)
            .then((response) => {
                timedAttemptMetrics.setSuccess();
                ApiUtil.apiMetrics.getChildActionPublisherForMethod(resource).publish(timedAttemptMetrics);
                return response.data;
            }).catch((error) => {
                if (error.response.status === 400) {
                    ApiUtil.apiMetrics.publishCounter(resource, 'error_400');

                    throw new ApiError(400, "invalid requests");
                } else if (error.response.status === 401) {
                    ApiUtil.apiMetrics.publishCounter(resource, 'error_401');

                    throw new ApiError(401, "unauthorized request");
                } else if (error.response.status === 403) {
                    ApiUtil.apiMetrics.publishCounter(resource, 'error_403');

                    throw new ApiError(403, "forbidden request");
                } else if (error.response.status === 429) {
                    ApiUtil.apiMetrics.publishCounter(resource, 'error_429');

                    throw new ApiError(429, "throttled request");
                } else {
                    ApiUtil.apiMetrics.publishCounter(resource, 'error_500');

                    timedAttemptMetrics.setFailure();
                    ApiUtil.apiMetrics.getChildActionPublisherForMethod(resource).publish(timedAttemptMetrics);

                    throw new ApiError(500, "failed to fetch data from api");
                }
            })
    }

    public static injectMetrics(metrics: MetricsUtil): void {
        ApiUtil.apiMetrics = metrics;
    }

    public static callApiWithTimeout(
        resource: string,
        payloads?: { [key: string]: any; },
        timeout = ApiUtil.default_api_call_timeout,
        method = HTTP_METHOD.GET,
        headers = {}
    ) {
        return Promise.race([
            ApiUtil.callApi(resource, payloads, method, headers),
            new Promise((_, reject) =>
                setTimeout(() => {
                    reject(new ApiError(408, 'timeout to call api'))
                }, timeout)
            )
        ]);
    }

    public static postApiWithTimeout(
        resource: string,
        payloads?: { [key: string]: any; },
        timeout = ApiUtil.default_api_call_timeout,
        method = HTTP_METHOD.POST,
    ) {
        let headers = {
            'anti-csrftoken-a2z': ApiUtil.csrfToken,
            'content-type': 'application/json; charset=UTF-8'
        };

        return ApiUtil.callApiWithTimeout(resource, payloads, timeout, method, headers);
    }

    private static chooseMethod(method: HTTP_METHOD,
                                url: string,
                                payloads?: { [key: string]: any; },
                                headers = {}) {
        if (method === HTTP_METHOD.POST) {
            return API.post("FeeTechPortalLambda", url, {
                headers: headers,
                response: true,
                body: payloads
            })
        }
        return API.get("FeeTechPortalLambda", url, {
            headers: headers,
            response: true,
        })
    }
}