import RequestStatus from "src/model/RequestStatus";
import {Profile} from "src/model/Profile";

export const REVISION_SAVE_START = 'REVISION_SAVE_START';
export const REVISION_SAVE_SUCCESS = 'REVISION_SAVE_SUCCESS';
export const REVISION_SAVE_FAILED = 'REVISION_SAVE_FAILED';

export interface RevisionSaveStartAction {
    readonly type: string;
    readonly requestStatus: RequestStatus;
    readonly projectId: string;
    readonly profiles: Profile[];
}

export interface GetRevisionSaveComplete {
    readonly type: string;
    readonly requestStatus: RequestStatus;
}

export const setRevisionSaveStart = (projectId: string, profile: Profile[]): RevisionSaveStartAction => ({
    type: REVISION_SAVE_START,
    requestStatus: RequestStatus.LOADING,
    projectId: projectId,
    profiles: profile
});

export const getRevisionSaveSuccessAction = (): GetRevisionSaveComplete => ({
    type: REVISION_SAVE_SUCCESS,
    requestStatus: RequestStatus.SUCCESS,
});

export const getRevisionSaveFailedAction = (): GetRevisionSaveComplete => ({
    type: REVISION_SAVE_FAILED,
    requestStatus: RequestStatus.ERROR,
});