import React from 'react';
import ReactDOM from 'react-dom';
import {KatSpinner} from '@amzn/katal-react';
import createSagaMiddleware from "redux-saga";
import {applyMiddleware, combineReducers, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";

import AppInitWrapper from './components/AppInitWrapper';
import './i18n';
import './logger';

import reducers from "src/reducers/combinedReducer";
import combinedSaga from "src/sagas/combinedSaga";
import '@amzn/katal-components/styles.css';
import './index.scss';
import ApplicationState from "src/model/ApplicationState";
import {Provider} from "react-redux";
import AppMainLayout from "src/components/AppMainLayout";

// The Suspense component causes React to display the fallback KatSpinner until
// the i18n translation strings have been loaded.

const sagaMiddleware = createSagaMiddleware();
const store = createStore(combineReducers<ApplicationState>(reducers), composeWithDevTools(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(combinedSaga);

ReactDOM.render(
    <React.StrictMode>
        <AppInitWrapper>
            <React.Suspense fallback={<KatSpinner size='large'/>}>
                <Provider store={store}>
                    <AppMainLayout/>
                </Provider>
            </React.Suspense>
        </AppInitWrapper>
    </React.StrictMode>,
    document.getElementById('root')
);
