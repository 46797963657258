import {call, put, takeLatest} from "redux-saga/effects";
import ApiUtil, {HTTP_METHOD} from "src/utils/ApiUtil";
import {
    deleteFutureFeeConfigFailed,
    deleteFutureFeeConfigSuccess,
    FUTURE_FEE_CONFIG_DELETE_START,
    FutureFeeConfigDeleteStartAction, setFutureFeeConfigQueryStart,
} from "src/actions/FutureFeeConfigQueryAction";

export function* deleteFutureFeeConfig(action: FutureFeeConfigDeleteStartAction) {
    try {
        let parameters = {
            countryCode: action.countryCode,
            login: action.userLogin,
            feeType: action.feeType,
            date: action.date,
        }
        // @ts-ignore
        yield call(ApiUtil.callApiWithTimeout, `futurefeeconfig/delete`, parameters,
            30 * 1000, HTTP_METHOD.POST);

        yield put(deleteFutureFeeConfigSuccess());
        yield put(setFutureFeeConfigQueryStart())
    } catch (error) {
        yield put(deleteFutureFeeConfigFailed());
        return;
    }
}

export default function* futureFeeConfigDelete() {
    yield takeLatest(FUTURE_FEE_CONFIG_DELETE_START, deleteFutureFeeConfig);
}