import * as KatalMetrics from "@amzn/katal-metrics";
import KatalMetricsDriverSushi from "@amzn/katal-metrics-driver-sushi";
import KatalMetricTimedAttempt from "@amzn/katal-metrics/lib/metricObject/KatalMetricTimedAttempt";
import KatalMetricTimerStopwatch from "@amzn/katal-metrics/lib/metricObject/KatalMetricTimerStopwatch";
import initialMetricsPublisher, {makeMetricsDriver} from "src/metrics";

export const ActionName = {
    TIME_ON_PAGE_AFTER_PRODUCT_DEFINITION: 'TimeOnPageAfterProductDefinition',
    TIME_ON_PAGE_AFTER_PRODUCT_SEARCHING_OR_SELECTING: 'TimeOnPageAfterProductSearchingOrSelection',
    TIME_ON_ONE_PRODUCT: "TimeOnOneProduct"
}

export default class Metrics {
    private readonly driver: KatalMetrics.MetricsDriver;
    private readonly metricsPublisher: KatalMetrics.Publisher;
    private readonly methodNamePrefix: string;
    private readonly timers: Map<string, KatalMetricTimerStopwatch>;

    public constructor(driver:KatalMetrics.MetricsDriver, metricsPublisher: KatalMetrics.Publisher, methodNamePrefix:string = '') {
        this.driver = driver;
        this.metricsPublisher = metricsPublisher;
        this.methodNamePrefix = methodNamePrefix;
        this.timers = new Map();

        const cb = () => {
            this.publishFinalTimer();
        };

        //https://code.amazon.com/packages/KatalMetricsDriverSushi/blobs/df4e60b715bca5285597818c40e90431ec69c025/--/src/KatalMetricsDriverSushi.ts#L107
        if ((this.driver as any).beforeUnload) {
            (this.driver as any).beforeUnload(cb);// hook into sushi's unload process
        } else {
            window.addEventListener("beforeunload",cb);
        }
    }

    private getMethodName(methodName: string) {
        if(this.methodNamePrefix === '') {
            return methodName;
        }

        return this.methodNamePrefix + '.' + methodName;
    }

    /**
     * Used in sage. keep same with metricsUtil used in component.
     */
    public static getMetricsUtil(): Metrics {
        return new Metrics(makeMetricsDriver(), initialMetricsPublisher, 'FeeSXPortal');
    }

    /**
     * This should be only used for test purpose.
     */
    public static getDefaultMetricUtil(): Metrics {
        const metricsConsoleErrorHandler = (err: Error) => console.error(err);

        const metricsDriver = new KatalMetricsDriverSushi.Builder()
            .withDomainRealm('test', 'USAmazon')
            .withErrorHandler(metricsConsoleErrorHandler)
            .build();

        const initialMetricsContext = new KatalMetrics.Context.Builder()
            .withSite('FeeTechPortal')
            .withServiceName('FeeTechPortalService')
            .build();

        const defaultPublisher = new KatalMetrics.Publisher(
            metricsDriver,
            metricsConsoleErrorHandler,
            initialMetricsContext
        );

        return new Metrics(metricsDriver, defaultPublisher);
    }

    /**
     * Provide original Katal metrics publisher, in case user wants to use some methods provided by Katal Metrics.
     */
    public getMetricsPublisher(): KatalMetrics.Publisher {
        return this.metricsPublisher;
    }

    /**
     * Provide original Katal metrics childActionPublisherForMethod, in case user wants to use this instance.
     * @param methodName
     */
    public getChildActionPublisherForMethod(methodName: string): KatalMetrics.Publisher {
        return this.getMetricsPublisher().newChildActionPublisherForMethod(this.getMethodName(methodName));
    }

    public publishString(methodName: string, value: string): void {
        const actionPublisher = this.getChildActionPublisherForMethod(methodName);
        actionPublisher.publishString(this.getMethodName(methodName), value);
    }

    /**
     * Publish counter on PMET
     * @param methodName
     * @param counterName
     * @param value
     */
    public publishCounter(methodName: string, counterName: string, value: number = 1): void {
        const actionPublisher = this.getChildActionPublisherForMethod(methodName);
        actionPublisher.publishCounterMonitor(counterName, value);
    }

    /**
     * Publish timer on PMET
     * @param methodName
     * @param timerName
     * @param value
     */
    public publishTimer(methodName: string, timerName: string, value: number): void {
        const actionPublisher = this.getChildActionPublisherForMethod(methodName);
        actionPublisher.publishTimerMonitor(timerName, value);
    }

    /**
     * Get timed attempt metrics instance
     * @param name
     */
    public static getTimedAttemptMetrics(name: string): KatalMetricTimedAttempt {
        return new KatalMetrics.Metric.TimedAttempt(name).withMonitor();
    }

    /**
     * If action timer has been set, publish it and reset timerWatch.
     * @param actionName
     */
    public setShortTimer(actionName: string): void {
        if (this.timers.get(actionName)) {
            this.publishActionTime(actionName, 'actionTime');
        }
        this.timers.set(actionName, new KatalMetricTimerStopwatch(actionName));
    }

    /**
     * If action timer has been set, return.
     * When closing page, publish the total time.
     * @param actionName
     */
    public setLongTimer(actionName: string): void {
        if (this.timers.get(actionName)) return;
        this.timers.set(actionName, new KatalMetricTimerStopwatch(actionName));
    }

    public getTimer(actionName: string): KatalMetricTimerStopwatch|undefined {
        return this.timers.get(actionName);
    }

    public publishActionTime(actionName:string, timerName:string, maxTime?: number): void {
        const timer = this.getTimer(actionName);
        if(!timer) return;

        let time = timer.now() - timer.startTime;
        if (maxTime) {
            time = time > maxTime ? maxTime : time;
        }

        this.publishTimer(actionName, timerName, time);
    }

    public publishFinalTimer(): void {
        this.timers.forEach((timer, actionName) => {
            this.publishActionTime(actionName, "finalTime");
        });
    }
}