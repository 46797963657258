import RequestStatus from "src/model/RequestStatus";
import {DeleteFutureFeeConfigResponse} from "src/model/FutureFee";
import {
    FUTURE_FEE_CONFIG_DELETE_FAILED,
    FUTURE_FEE_CONFIG_DELETE_START, FUTURE_FEE_CONFIG_DELETE_SUCCESS,
    FutureFeeConfigDeleteComplete,
} from "src/actions/FutureFeeConfigQueryAction";

const initialState: DeleteFutureFeeConfigResponse = {
    requestStatus: RequestStatus.NOT_LOADED,
};

export default (
    state: DeleteFutureFeeConfigResponse = initialState,
    action: FutureFeeConfigDeleteComplete
): DeleteFutureFeeConfigResponse => {
    switch (action.type) {
        case FUTURE_FEE_CONFIG_DELETE_START:
            return {
                requestStatus: RequestStatus.LOADING,
            };
        case FUTURE_FEE_CONFIG_DELETE_SUCCESS:
            return {
                requestStatus: RequestStatus.SUCCESS,
            };
        case FUTURE_FEE_CONFIG_DELETE_FAILED:
            return {
                requestStatus: RequestStatus.ERROR,
            };
        default:
            return state;
    }
};