import RequestStatus from "../model/RequestStatus";
import {
    GetProfileListQueryComplete,
    PROFILE_LIST_QUERY_FAILED,
    PROFILE_LIST_QUERY_START,
    PROFILE_LIST_QUERY_SUCCESS
} from "src/actions/ProfileListQueryAction";
import {GetProfileListQueryResponse} from "src/model/Profile";

const initialState: GetProfileListQueryResponse = {
    requestStatus: RequestStatus.NOT_LOADED,
    data: null,
};

export default (
    state: GetProfileListQueryResponse = initialState,
    action: GetProfileListQueryComplete
): GetProfileListQueryResponse => {
    switch (action.type) {
        case PROFILE_LIST_QUERY_START:
            return {
                requestStatus: RequestStatus.LOADING,
                data: null,
            };
        case PROFILE_LIST_QUERY_SUCCESS:
            return {
                requestStatus: RequestStatus.SUCCESS,
                data: action.profileList,
            };
        case PROFILE_LIST_QUERY_FAILED:
            return {
                requestStatus: RequestStatus.ERROR,
                data: null,
            };
        default:
            return state;
    }
};
