import {call, put, takeLatest} from 'redux-saga/effects';
import {
    getProfileListQueryFailedAction,
    getProfileListQuerySuccessAction,
    PROFILE_LIST_QUERY_START,
    ProfileListQueryStartAction
} from "src/actions/ProfileListQueryAction";
import ApiUtil from "src/utils/ApiUtil";

export function* getProfileList(action: ProfileListQueryStartAction) {
    try {
        // @ts-ignore
        const response = yield call(ApiUtil.callApiWithTimeout, `banner/profiles/${action.projectId}/${action.revisionId}`);
        //Object.keys(response.).map
        yield put(getProfileListQuerySuccessAction(response));
    } catch (error) {
        console.error(`Call API /profiles/${action.projectId}/${action.revisionId} error` + error)
        yield put(getProfileListQueryFailedAction());
        return;
    }
}

export default function* profileListQuery() {
    yield takeLatest(PROFILE_LIST_QUERY_START, getProfileList);
}