import RequestStatus from "../model/RequestStatus";
import {ProjectListQueryResponse} from "src/model/Project";

export const PROJECT_LIST_QUERY_START = 'PROJECT_LIST_QUERY_START';
export const PROJECT_LIST_QUERY_SUCCESS = 'PROJECT_LIST_QUERY_SUCCESS';
export const PROJECT_LIST_QUERY_FAILED = 'PROJECT_LIST_QUERY_FAILED';

export interface ProjectListQueryStartAction {
    readonly type: string;
    readonly requestStatus: RequestStatus;
}

export const setProjectListQueryStart = (): ProjectListQueryStartAction => ({
    type: PROJECT_LIST_QUERY_START,
    requestStatus: RequestStatus.LOADING,
});

export interface GetProjectListQueryComplete {
    readonly type: string;
    readonly requestStatus: RequestStatus;
    readonly projectList: any
}

export const getProjectListQuerySuccessAction = (response: ProjectListQueryResponse): GetProjectListQueryComplete => ({
    type: PROJECT_LIST_QUERY_SUCCESS,
    requestStatus: RequestStatus.SUCCESS,
    projectList: response
});

export const getProjectListQueryFailedAction = (): GetProjectListQueryComplete => ({
    type: PROJECT_LIST_QUERY_FAILED,
    requestStatus: RequestStatus.ERROR,
    projectList: null
});