import {call, put, takeLatest} from 'redux-saga/effects';
import {
    getRevisionListQueryFailedAction,
    getRevisionListQuerySuccessAction,
    REVISION_LIST_QUERY_START,
    RevisionListQueryStartAction
} from "src/actions/RevisionListQueryAction";
import ApiUtil from "src/utils/ApiUtil";

export function* getRevisionList(action: RevisionListQueryStartAction) {
    try {
        // @ts-ignore
        const response = yield call(ApiUtil.callApiWithTimeout, `banner/revisions/${action.projectId}`);
        yield put(getRevisionListQuerySuccessAction(response));
    } catch (error) {
        console.error(`Call API /revisions/${action.projectId} error` + error)
        yield put(getRevisionListQueryFailedAction());
        return;
    }
}

export default function* revisionListQuery() {
    yield takeLatest(REVISION_LIST_QUERY_START, getRevisionList);
}