import RequestStatus from "../model/RequestStatus";
import {GetRevisionSaveResponse} from "src/model/Revision";
import {
    GetRevisionSaveComplete,
    REVISION_SAVE_FAILED,
    REVISION_SAVE_START,
    REVISION_SAVE_SUCCESS
} from "src/actions/RevisionSaveAction";

const initialState: GetRevisionSaveResponse = {
    requestStatus: RequestStatus.NOT_LOADED,
};

export default (
    state: GetRevisionSaveResponse = initialState,
    action: GetRevisionSaveComplete
): GetRevisionSaveResponse => {
    switch (action.type) {
        case REVISION_SAVE_START:
            return {
                requestStatus: RequestStatus.UPDATING,
            };
        case REVISION_SAVE_SUCCESS:
            return {
                requestStatus: RequestStatus.SUCCESS,
            };
        case REVISION_SAVE_FAILED:
            return {
                requestStatus: RequestStatus.ERROR,
            };
        default:
            return state;
    }
};
