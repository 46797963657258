import {call, put, takeLatest} from "redux-saga/effects";
import ApiUtil from "src/utils/ApiUtil";
import {
    FUTURE_FEE_CONFIG_QUERY_START,
    FutureFeeConfigQueryStartAction, getFutureFeeConfigFailedAction,
    getFutureFeeConfigSuccessAction
} from "src/actions/FutureFeeConfigQueryAction";
import {FutureFeeConfig} from "src/model/FutureFee";

export function* getFutureFeeConfig(action: FutureFeeConfigQueryStartAction) {
    try {
        // @ts-ignore
        const response = yield call(ApiUtil.callApiWithTimeout, `futurefeeconfig`);
        const futureFeeConfigMap = new Map<string, Map<string, Array<number>>>();
        const responseMap = response.futureFeeConfigMap;
        for (const marketplace in responseMap) {
            const marketplaceData = responseMap[marketplace];
            const feeTypeMap = new Map<string, Array<number>>();

            for (const feeType in marketplaceData) {
                const feeTypeData = marketplaceData[feeType];
                feeTypeMap.set(feeType, feeTypeData);
            }

            futureFeeConfigMap.set(marketplace, feeTypeMap);
        }

        const futureFeeConfig: FutureFeeConfig = {
            futureFeeConfigMap: futureFeeConfigMap,
        };

        yield put(getFutureFeeConfigSuccessAction(futureFeeConfig));
    } catch (error) {
        yield put(getFutureFeeConfigFailedAction());
        return;
    }
}

export default function* futureFeeConfigQuery() {
    yield takeLatest(FUTURE_FEE_CONFIG_QUERY_START, getFutureFeeConfig);
}