import React from "react";
import {KatSpinner} from "@amzn/katal-react";
import Size = KatSpinner.Size;

interface Props {
    size?: string
}

export const LoadingStatusComponent = (props: Props) => {
    const {size} = props

    return(
        <>
            <div className="loadingBoxStyle" >
                <KatSpinner size={size ? size as Size : "large"}/>
            </div>
        </>
    )
};

export default LoadingStatusComponent;