import {call, put, takeLatest} from 'redux-saga/effects';
import {
    setRevisionListQueryStart
} from "src/actions/RevisionListQueryAction";
import ApiUtil from "src/utils/ApiUtil";
import {
    getRevisionSaveFailedAction,
    getRevisionSaveSuccessAction,
    REVISION_SAVE_START,
    RevisionSaveStartAction
} from "src/actions/RevisionSaveAction";

export function* saveRevision(action: RevisionSaveStartAction) {
    try {
        const params = {
            projectId: action.projectId,
            profiles: action.profiles
        }

        yield call(ApiUtil.postApiWithTimeout, `banner/revision`, params);
        yield put(getRevisionSaveSuccessAction());
        yield put(setRevisionListQueryStart(action.projectId));
    } catch (error) {
        console.error(`Call API Post /revision error` + error)
        yield put(getRevisionSaveFailedAction());
        return;
    }
}

export default function* revisionSave() {
    yield takeLatest(REVISION_SAVE_START, saveRevision);
}